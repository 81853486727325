@import '~/bb/ui/Styles/variables';

.base {
    width: 100%;
    overflow: hidden;
}

.card {
    overflow: hidden;
}
