@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.root {
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 480px;
    max-height: 80vh;
    overflow-y: auto;
    opacity: 0;
    transition-timing-function: ease-out;
    transition-duration: var(--modal-animation-duration);
    transition-property: opacity, scale;
    scale: 0.5;
    translate: -50% -50%;

    &[data-isopen='true'] {
        opacity: 1;
        scale: 1;
    }

    @media (max-width: $screen-size-sm) {
        top: auto;
        bottom: 0;
        left: 0;
        width: 100vw;
        max-width: none;
        opacity: 1;
        transition-property: translate;
        scale: 1;
        translate: 0 100%;

        &[data-isopen='true'] {
            translate: 0;
        }
    }
}

.close {
    position: absolute;
    top: 0;
    right: 0;
}

.showMoreToggleButton {
    display: block;
}
