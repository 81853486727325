@import '~/bb/ui/Styles/variables';

.base {
    display: grid;
    place-items: center;
    background-color: var(--color-white);

    @media (min-width: $screen-size-xl) {
        background-color: var(--color-gray-01);
    }

    main {
        width: 100%;
        height: 100%;
        margin: var(--distance-small) 0;
    }
}

.automaticSizeInDesktop {
    main {
        @media (min-width: $screen-size-xl) {
            width: auto;
            height: auto;
        }
    }
}
