@import '~/bb/ui/Styles/variables';

.root {
    width: 100%;
}

$light-green: #dcfadc;
$dark-green: #51d763;

.wrapper {
    position: relative;
    background: linear-gradient(to right, $dark-green 50%, $light-green 50%);

    --shape-container-width: 160px;
    --circle-width: 128px;

    @media screen and (min-width: $screen-size-sm) {
        --circle-width: 172px;
        --shape-container-width: 215px;
    }

    & > *:first-child {
        width: 100%;
    }
}

.setWidth {
    width: var(--shape-container-width);
}

.box {
    position: relative;
    z-index: 1;
    padding-top: var(--distance-medium);
    overflow: hidden;

    svg {
        width: 100%;
    }
}

.firstBox {
    svg {
        fill: $light-green;
    }
}

.secondBox {
    left: -1px;
    z-index: 0; // put it behind the left section to still have a 50% split

    .boxHeader,
    .smileyWrapper {
        margin-left: auto;
    }

    .smileyWrapper {
        right: 0;
    }

    svg {
        fill: $dark-green;
        scale: -1 1;
    }
}

.boxHeader {
    width: var(--circle-width);
    height: 3rem;
}

.shapeWrapper {
    position: relative;
    display: flex;
}

.smileyWrapper {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--circle-width);
}

.smiley {
    position: absolute;
    top: calc(var(--circle-width) / 4);
    display: grid;
    place-items: center;
    width: calc((var(--circle-width) / 2) + 1px); // small overlap again
    aspect-ratio: 1/1;
    margin-top: -1px; // small overlap
    background: var(--color-tertiary-purple);
    border-radius: 50%;
}

.shapeText {
    margin-top: 0.4rem;

    @media screen and (min-width: $screen-size-sm) {
        margin-top: 0.8rem;
    }
}

.share {
    max-width: 400px;
    min-height: 190px;
    padding: var(--distance-small);
    margin-bottom: var(--distance-medium);
}
