@import '~/bb/ui/Styles/variables';

.sticky {
    position: fixed;
    top: var(--navbar-height);
    right: 0;
    left: 0;
    z-index: var(--z-index-nav);
    width: 100%;
    height: fit-content;
}

.floating {
    position: fixed;
    right: var(--distance-small);
    bottom: var(--distance-small);
    z-index: 199;
    width: 20%;
    min-width: 400px;

    .flexContainer {
        width: 100%;
    }

    .header {
        position: relative;
        top: -4px;
        max-width: 85%;
    }

    @media (width <= 768px) {
        right: 0;
        bottom: 0;
        left: 0;
        width: 90%;
        min-width: auto;
        margin: 5%;
    }
}
