@import '~/bb/ui/Styles/variables';

.content {
    max-width: 1200px;
    min-height: 60vh;
    padding: var(--distance-extra-small);
    margin-bottom: var(--distance-large);
    background-color: var(--color-primary-white);

    @media (width >= 576px) {
        margin-top: var(--distance-large);
        margin-right: var(--distance-extra-small);
        margin-left: var(--distance-extra-small);
    }

    @media (width >= 1024px) {
        padding: var(--distance-medium);
        margin-top: var(--distance-large);
    }
}

.noBg {
    background-color: transparent;
}
