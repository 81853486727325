@import '~/bb/ui/Styles/variables';

.buttonWrapper {
    @media (max-width: $screen-size-sm) {
        position: sticky;
        bottom: 0;
    }
}

.submitButton {
    width: 320px;

    @media (max-width: $screen-size-sm) {
        width: 100%;
    }
}
